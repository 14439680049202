import { ResponseType, Users } from '../../types';
import useSWRImmutable from 'swr/immutable';

type ApiResponse = ResponseType<Omit<Users, 'password'>>;

function useGetMe() {
  const { data, error, ...rest } = useSWRImmutable<ApiResponse, Error, string>('/admin/me', {
    shouldRetryOnError: false,
    errorRetryCount: 0,
    errorRetryInterval: 0
  });

  return {
    me: data?.data,
    error,
    ...rest
  };
}

export default useGetMe;
