import { RouteObject, useLoaderData } from 'react-router-dom';
import appRoutes from '.';
import AuthRoute from './AuthRoute';
import { ComponentType } from 'react';

export default function getRouteObjectFromRoutes(routes: typeof appRoutes) {
  const routeObjects = Object.values(routes)
    .filter(route => route.component)
    .map(route => {
      const doesRequireAuth = route.auth;
      let element = <Loader />;
      if (doesRequireAuth) {
        element = <AuthRoute>{element}</AuthRoute>;
      }
      const routeObject: RouteObject = {
        element,
        path: route.path,
        loader: route.component.preload
      };
      return routeObject;
    });
  return routeObjects;
}

function Loader() {
  const { default: Component } = useLoaderData() as { default: ComponentType<unknown> };

  return <Component />;
}
