import { Menu, MenuButton, Avatar, MenuList, MenuItem } from '@chakra-ui/react';
import { useAuth } from '../App/GlobalProviders/AuthProvider';
import { useNavigate } from 'react-router-dom';

function AuthenticatedAvatar() {
  const { user, logout } = useAuth();
  const userName = user?.name;
  const navigate = useNavigate();

  const onClickLogOut = () => {
    logout();
    navigate('/login');
  };

  return (
    <Menu>
      <MenuButton>
        <Avatar name={userName} size="sm" />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onClickLogOut}> Log out</MenuItem>
      </MenuList>
    </Menu>
  );
}

export default AuthenticatedAvatar;
