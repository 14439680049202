import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';

import getRouteObjectFromRoutes from './utils/routes/getRouteObjectFromRoutes';
import theme from './theme';
import appRoutes from './utils/routes';
import App from './components/App/App';

const { ToastContainer } = createStandaloneToast();

const routes = createBrowserRouter([
  {
    element: <App />,
    children: [...getRouteObjectFromRoutes(appRoutes), { path: '*', element: <div>Not Found</div> }]
  }
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ChakraProvider theme={theme} resetCSS>
      <ToastContainer />
      <RouterProvider router={routes} />
    </ChakraProvider>
  </React.StrictMode>
);
