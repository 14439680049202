import { Center, Spinner } from '@chakra-ui/react';
import { useEffect, useReducer } from 'react';
import { Navigate } from 'react-router-dom';
import useGetMe from '../../../services/authentication/useGetMe';
import { User, isUser } from '../../../types';
import createContext from '../../../utils/context/createContext';
import { RESTRICT_AUTH } from '../../../utils/routes';

interface AuthContextType {
  isAuthenticated: boolean;
  user: Omit<User, 'password'> | null;
  login: (data: Omit<User, 'password'> & { accessToken: string }) => void;
  logout: () => void;
  isMeLoaded: boolean;
}

type AuthProviderProps = {
  children: React.ReactNode;
};

type AuthReducerAction = { type: 'login'; payload: Omit<User, 'password'> } | { type: 'logout'; payload?: never };

type AuthReducerState = {
  isAuthenticated: boolean;
  user: null | User;
};

const [useAuth, Provider] = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  login: () => void {},
  logout: () => void {},
  isMeLoaded: false,
});

function authReducer(state: AuthReducerState, action: AuthReducerAction) {
  switch (action.type) {
    case 'login':
      if (!action.payload || !isUser(action.payload)) throw new Error('No user provided');

      return { ...state, isAuthenticated: true, user: action.payload };
    case 'logout':
      return { ...state, isAuthenticated: false, user: null };
  }
}

function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const [authState, dispatch] = useReducer(authReducer, { isAuthenticated: false, user: null });
  const { me, error } = useGetMe();

  // NOTE: when app re-opens / refreshes and there is an access token in local storage
  // then restore the user from the access token by a me query.
  useEffect(() => {
    const isAccessTokenInLocalStorage = localStorage.getItem('accessToken');
    if (isAccessTokenInLocalStorage && me) {
      dispatch({ type: 'login', payload: me });
    }
  }, [me]);

  const login: AuthContextType['login'] = data => {
    const { accessToken, ...user } = data;
    localStorage.setItem('accessToken', accessToken);
    dispatch({ type: 'login', payload: user });
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'logout' });
  };

  if ((authState.isAuthenticated && authState.user)  || RESTRICT_AUTH.includes(window.location.pathname) ) {
    return <Provider value={{ ...authState, login, logout, isMeLoaded: !!me }}>{children}</Provider>;
  }

  if (!error) {
    return <Center height="100vh">
      <Spinner size="xl" color="blue.500" thickness="4px" />
    </Center>
  }


  return <Navigate to="/login" replace />
}

export { AuthProvider, useAuth };

