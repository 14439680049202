import SWRProvider from '../SwrProvider';
import { AuthProvider } from './AuthProvider';
import { ReactNode } from 'react';

type GlobalProvidersProps = {
  children: ReactNode;
};

function GlobalProviders(props: GlobalProvidersProps) {
  const { children } = props;

  return (
    <SWRProvider>
      <AuthProvider>{children}</AuthProvider>
    </SWRProvider>
  );
}

export default GlobalProviders;
