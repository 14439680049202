import { Box, Heading, HStack, VStack } from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';
import { HiOutlineExclamation } from 'react-icons/hi';
import ResetWrapper from './ResetWrapper';

function InternalServerError(props: FallbackProps) {
  const { resetErrorBoundary } = props;

  return (
    <ResetWrapper resetErrorBoundary={resetErrorBoundary}>
      <VStack width={{ base: '100%', md: '70%' }} justify="center" height="40vh" spacing="0">
        <HStack alignItems="center" mb="2" spacing="4" color="red.400">
          <Box mt="1.5rem" fontSize={{ base: '7xl', md: '9xl' }}>
            <HiOutlineExclamation />
          </Box>
          <Heading fontWeight="extrabold" fontSize={{ base: '7xl', md: '9xl' }}>
            500
          </Heading>
        </HStack>
        <Box fontSize="2xl" letterSpacing="wide">
          Internal Server Error
        </Box>
        <Box fontSize="xl" color="gray.500" textAlign="center">
          Error occured while doing some operation. Please try again.
        </Box>
      </VStack>
    </ResetWrapper>
  );
}

export default InternalServerError;
