import type { PreloadableComponent } from '../pages/lazyPage';
import pageMaps from '../pages/pageMaps';

export type RouteConfig = {
  path: string;
  icon?: React.ReactNode;
  title?: string;
  component: PreloadableComponent;
  auth?: boolean;
};

function asAppRoute<T>(obj: { [K in keyof T]: RouteConfig }) {
  return obj;
}

const appRoutes = asAppRoute({
  home: {
    path: '/',
    component: pageMaps.users,
    auth: true,
  },
  users: {
    path: '/admin/users',
    component: pageMaps.users,
    auth: true,
  },
  content: {
    path: '/admin/content',
    component: pageMaps.content,
    auth: true,
  },
  post: {
    path: '/admin/post/:type/:postId',
    component: pageMaps.post,
    auth: true,
  },
  postDetails: {
    path: '/admin/post-detail/:postId',
    component: pageMaps.postDetails,
    auth: true,
  },
  reports: {
    path: '/admin/reports',
    component: pageMaps.reports,
    auth: true,
  },
  add_user: {
    path: '/admin/add-user',
    component: pageMaps.add_user,
    auth: true,
  },
  update_user_status: {
    path: '/admin/users/update-user-status',
    component: pageMaps.update_user_status,
    auth: true,
  },
  delete_user: {
    path: '/admin/users/delete-user',
    component: pageMaps.delete_user,
    auth: true,
  },
  profile: {
    path: '/admin/profile/:username',
    component: pageMaps.profile,
    auth: true,
  },
  login: {
    path: '/login',
    component: pageMaps.login,
  },
});

export default appRoutes;

const HIDDEN_HEADER_ROUTES = ['/new', '/login', '/signup'];
export const RESTRICT_AUTH = ['/login', '/signup']

export function isHeaderHiddenForRoute(route: string) {
  return HIDDEN_HEADER_ROUTES.includes(route);
}
