import { Button, ButtonProps, VStack } from '@chakra-ui/react';
import { FcHome, FcBusinessman, FcParallelTasks, FcQuestions, FcAnswers } from 'react-icons/fc';
import { ReactNode } from 'react';
import { NavLinkProps, NavLink, useLocation } from 'react-router-dom';

type NavbarProps = { onClickNavbarItem?: ButtonProps['onClick'] };

function Navbar(props: NavbarProps) {
  const { onClickNavbarItem } = props;
  return (
    <VStack
      as="nav"
      width={240}
      gap={2}
      align="left"
      __css={{
        '.chakra-button__icon': {
          fontSize: '2xl'
        }
      }}
    >
      {/* <NavBarItem leftIcon={<FcHome />} to="/" onClick={onClickNavbarItem}>
        Dashboard
      </NavBarItem> */}
      <NavBarItem leftIcon={<FcBusinessman />} to="/admin/users" onClick={onClickNavbarItem}>
        Users
      </NavBarItem>
      <NavBarItem leftIcon={<FcParallelTasks />} to="/admin/content" onClick={onClickNavbarItem}>
        Content
      </NavBarItem>
      <NavBarItem leftIcon={<FcAnswers />} to="/admin/reports" onClick={onClickNavbarItem}>
        Reports
      </NavBarItem>
    </VStack>
  );
}

type NavBarItemProps = Pick<ButtonProps, 'leftIcon' | 'onClick'> & Pick<NavLinkProps, 'to'>;

function NavBarItem(props: NavBarItemProps & { children: ReactNode }) {
  const { children, leftIcon, to, onClick } = props;

  const { pathname } = useLocation();
  const isCurrentPath = pathname === to;

  return (
    <Button
      as={NavLink}
      variant="link"
      _hover={{ bg: 'gray.200', color: 'gray.700', textDecor: 'underline' }}
      color="black"
      bg={isCurrentPath ? 'gray.200' : 'transparent'}
      fontWeight="normal"
      justifyContent="flex-start"
      leftIcon={leftIcon}
      p="8px 16px"
      to={to}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default Navbar;
