export type User = {
  _id: string;
  name: string;
  email: string;
  userName: string;
  password: string;
  createdAt: string;
  status: string;
};

export type UserColumn = {
  _id: string;
  name: string;
  email: string;
  userName: string;
  status: any;
  actions: any;
  canReport: boolean;
};

export type Users = {
  _id: string;
  name: string;
  email: string;
  userName: string;
  password: string;
  status: string;
  createdAt: string;
};

export function isUser(user: Record<string, string>): user is User {
  return (
    user &&
    typeof user.name === 'string' &&
    typeof user.email === 'string' &&
    typeof user.userName === 'string'
  );
}
