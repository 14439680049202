import { ReactNode, useMemo } from 'react';
import { SWRConfig, SWRConfiguration } from 'swr';

const fetcher = async (url: string) => {
  const fullUrl = `${import.meta.env.VITE_SERVICE_URL}${url}`;
  const accessToken = localStorage.getItem('accessToken');

  const response = await fetch(fullUrl, {
    headers: {
      Accept: 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    }
  });

  if (!response.ok) {
    const errorInfo = await response.json();
    const error = new Error(errorInfo.message);
    throw error;
  }

  return response.json();
};

function SWRProvider(props: { children: ReactNode }) {
  const { children } = props;

  const swrOption = useMemo<SWRConfiguration>(
    () => ({
      revalidateIfStale: true,
      revalidateOnFocus: true,
      fetcher
    }),
    []
  );

  return <SWRConfig value={swrOption}>{children}</SWRConfig>;
}

export default SWRProvider;