import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../components/App/GlobalProviders/AuthProvider';

type AuthRouteProps = {
  children: ReactNode;
};

function AuthRoute(props: AuthRouteProps) {
  const { children } = props;
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
}

export default AuthRoute;
