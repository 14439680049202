import {
  Box,
  Button,
  ButtonProps,
  Container,
  Flex,
  Icon,
  IconButton,
  IconButtonProps,
  Portal,
  Show,
  Spacer,
  Tooltip,
  useDisclosure,
  HStack
} from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link, LinkProps } from 'react-router-dom';
import { Logo } from './Logo';
import { SideBar } from '../Navbar';
import AuthenticatedAvatar from './AuthenticatedAvatar';

function Header() {
  
  const { isOpen: isSidebarOpen, onClose: onSidebarClose, onOpen: onSidebarOpen } = useDisclosure();

  return (
    <Box as="header" bg="white" boxShadow="sm" position="sticky" top="0" left="0" zIndex="banner">
      <Container maxWidth="6xl">
        <Flex align="center" gap={{ base: '0.8', md: '3' }}>
          <HStack>
            <IconButton variant="link" aria-label="sidebar" onClick={onSidebarOpen} hideFrom="md">
              <Icon fontSize="2xl">
                <GiHamburgerMenu />
              </Icon>
            </IconButton>
            <Logo asLink />
          </HStack>
          {/** Show this only on larger screen */}
          <Show above="md">
            
            <Spacer />
           
          </Show>
          {/** Show this only on smaller screen */}
          <Show below="md">
            <Spacer />
            
            <HeaderIconButton as={Link} to="/new" aria-label="Post idea">
              <FaPlus />
            </HeaderIconButton>
          </Show>
          <AuthenticatedAvatar />
        </Flex>
      </Container>
      <Portal>
        
        <SideBar isOpen={isSidebarOpen} onClose={onSidebarClose} />
      </Portal>
    </Box>
  );
}

type HeaderButtonProps = Pick<ButtonProps, 'rightIcon' | 'children' | 'onClick' | 'as'> &
  Pick<Partial<LinkProps>, 'to'>;

type HeaderIconButtonProps = Pick<IconButtonProps, 'onClick' | 'children' | 'as' | 'aria-label'> &
  Pick<Partial<LinkProps>, 'to'>;

function HeaderIconButton(props: HeaderIconButtonProps) {
  const { to, as, children, 'aria-label': ariaLabel, onClick } = props;

  return (
    <Tooltip label={ariaLabel}>
      <IconButton as={as} to={to} aria-label={ariaLabel} variant="link" onClick={onClick}>
        <Icon fontSize="2xl">{children}</Icon>
      </IconButton>
    </Tooltip>
  );
}

export default Header;
