import lazyPage from './lazyPage';

const pageMaps = {
  login: lazyPage(() => import('../../pages/login')),
  home: lazyPage(() => import('../../pages/home')),
  users: lazyPage(() => import('../../pages/users')),
  content: lazyPage(() => import('../../pages/content')),
  reports: lazyPage(() => import('../../pages/reports')),
  add_user: lazyPage(() => import('../../pages/addUser')),
  update_user_status: lazyPage(() => import('../../pages/addUser')),
  delete_user: lazyPage(() => import('../../pages/addUser')),
  profile: lazyPage(() => import('../../pages/profile')),
  post: lazyPage(() => import('../../pages/post')),
  postDetails: lazyPage(() => import('../../pages/post-detail')),
  question: lazyPage(() => import('../../pages/question')),
};

export default pageMaps;
