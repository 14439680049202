import { HStack, Box, Container } from '@chakra-ui/react';
import { Navbar } from '../Navbar';
import { useLocation } from 'react-router-dom';
import { isHeaderHiddenForRoute } from '../../utils/routes';

import { Header } from '../Header';

interface BaseLyoutProps {
  children: React.ReactNode;
}

function BaseLayout({ children }: BaseLyoutProps) {
  const { pathname } = useLocation();
  const isHeaderAndSidebarHidden = isHeaderHiddenForRoute(pathname);

  return (
    <Box position="relative">
      {!isHeaderAndSidebarHidden && <Header />}
      <Container pt={!isHeaderAndSidebarHidden ? '1rem' : 0} maxW="6xl" px="0">
        <HStack
          as="main"
          align="start"
          pb={!isHeaderAndSidebarHidden ? '4' : '0'}
          spacing={{ base: 0, md: isHeaderAndSidebarHidden ? 0 : 4 }}
        >
          <Box hideBelow="md" display={isHeaderAndSidebarHidden ? 'none' : 'block'} position="sticky" top="4.6rem">
            <Navbar />
          </Box>
          {children}
        </HStack>
      </Container>
    </Box>
  );
}

export default BaseLayout;
